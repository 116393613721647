<template>
    <div id="wrapper" v-if="false">
        <div class="row tophdr">
            <div class="col-10 offset-1">
                <h3><strong>About Us</strong></h3>
            </div>
        </div>

        <div class="row subhdr">
            <div class="col-10 offset-1">
                <p><img src="../assets/Region13_logo.png" alt="Education Service Center Region 13" class="logo-img" width="218" height="46" /></p>
                <p>
                    Education Service Center Region 13 (ESC 13) is one of twenty service centers that serve Texas' educational needs.
                    We are a non-regulatory agency that collaborates with schools and communities to promote quality instruction in order to maximize student performance.
                    We serve as a liaison between the Texas Education Agency and local school districts and campuses by disseminating information, conducting training,
                    and providing consultation for both federal and state programs.
                </p>
                <p>
                    ESC 13 provides professional development in areas such as technology, bilingual education, special education, gifted/talented education,
                    and programs for at-risk students. We offer consultation and support in the areas listed above as well as school improvement and special services.
                    In addition, we have developed and have available alternative teacher, principal and superintendent certification programs.
                </p>
            </div>
        </div>
        <hr />

        <div class="row midsect">
            <div class="col-10 offset-1">
                <p><img src="../assets/PublicImpact_logo.jpg" alt="Public Impact" class="logo-img" width="218" height="69" /></p>
                <p>
                    Public Impact's mission is to dramatically improve learning outcomes for all children in the U.S., with a special focus on students who are not served well.
                    Our work is designed to contribute to dramatic improvements in the quality of education and related policy supports in the United States.
                    We are a team of professionals from many backgrounds, including former teachers. We are researchers, thought leaders, policy experts, tool-builders,
                    and on-the-ground consultants who work with leading education reformers. In response to the challenge of turning around chronically low-performing schools,
                    Public Impact develops tools and provides technical assistance to states, districts, and schools.
                </p>
            </div>
        </div>
        <hr />

        <div class="row midsect2">
            <div class="col-10 offset-1">
                <h4>About the Turnaround 360 Feedback Assessment</h4>
                <p>
                    Education Service Center Region 13 and Public Impact collaborated to develop a tool to support turnaround school leaders in their professional growth.
                    The partnership utilizes Public Impact's research on turnaround leader competencies and actions with ESC 13's technical expertise to create
                    the Turnaround Leader 360 Feedback Assessment. The tool is designed to provide developmental feedback on the degree to which a school leader
                    demonstrates research-based turnaround leader actions and competencies. The feedback is based on perceptions of a select group of direct reports,
                    peers, a supervisor, and the leader's self-assessment.
                </p>
            </div>
        </div>
        <br />
        <br />
    </div>
    <br />
</template>

<script>
    export default {
        name: "About",

        mounted() {
            document.title = "OER Texas Review";
        }
    }
</script>

<style scoped>
    .tophdr {
        margin-top: 24px;
        margin-bottom: 20px;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding-top: 18px;
    }

    .ctr {
        text-align: center;
    }

    .midsect {
        background-color: #cce0ea;
        padding-top: 12px;
    }

    .midsect2 {
        background-color: #e1e9ed;
        padding-top: 18px;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>