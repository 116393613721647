<template>
    <header class="hdrstyle noprint" v-if ="false">
        <nav class="navbar navbar-expand-lg navbar-toggleable-lg navbar-light border-bottom box-shadow mb-3">
            <div class="container-fluid">
                <img class ="img-fluid" src="../assets/red_header_01.png" alt="Turnaround Leader 360 Feedback Assessment" />
                <button class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        aria-label="Toggle navigation"
                        @click="toggle">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse d-lg-inline-flex flex-lg-row-reverse"
                        v-bind:class="{show: isExpanded}">
                    <ul class="navbar-nav flex-grow">
                        <li class="nav-item">
                            <router-link :to="{ name: 'Login' }" v-show="$store.state.loggedName==''" class="nav-link text-light">Login</router-link>
                        </li>
                        <li class="nav-item" v-if="$store.state.userRole=='Administrator'">
                            <router-link :to="{ name: 'Admin' }" class="nav-link text-light"><HomeIcon class="icon" /> Home</router-link>
                        </li>
                        <li class="nav-item" v-else-if="$store.state.userRole=='Purchaser'">
                            <router-link :to="{ name: 'Purchaser' }" class="nav-link text-light"><HomeIcon class="icon" /> Home</router-link>
                        </li>
                        <li class="nav-item" v-else-if="$store.state.userRole=='Leader'">
                            <router-link :to="{ name: 'Leader' }" class="nav-link text-light"><HomeIcon class="icon" /> Home</router-link>
                        </li>
                        <li class="nav-item" v-else>
                            <router-link :to="{ name: 'Home' }" class="nav-link text-light"><HomeIcon class="icon" /> Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'About' }" class="nav-link text-light"><UserGroupIcon class="icon" /> About Us</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'Contact' }" class="nav-link text-light"><ChatAlt2Icon class="icon" /> Contact</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'Logout' }" v-show="$store.state.loggedName!=''" class="nav-link text-light">Logout</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
    import { HomeIcon } from '@heroicons/vue/solid';
    import { UserGroupIcon } from '@heroicons/vue/solid';
    import { ChatAlt2Icon } from '@heroicons/vue/solid';

    export default {
        name: "NavMenu",

        components: {
            HomeIcon,
            UserGroupIcon,
            ChatAlt2Icon
        },

        data() {
            return {
                isExpanded: false
            }
        },
        methods: {
            collapse() {
                this.isExpanded = false;
            },

            toggle() {
                this.isExpanded = !this.isExpanded;
            }
        }
    }
</script>

<style scoped>
    .hdrstyle {
        background-color: #191970;
    }

    a.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }

    html {
        font-size: 14px;
    }

    .box-shadow {
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    }

    .icon {
        height: 18px;
        width: auto;
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
    }


    @media (min-width: 768px) {
        html {
            font-size: 16px;
        }
    }

    @media print {
        .noprint {
            display: none;
        }
    }
</style>

