<template>
    <div class="wrapper" v-if="false">
        <div class="row">
            <div class="col footer d-block d-sm-none">
                <p>
                    Copyright &copy; <a href="http://www4.esc13.net/" target="blank" aria-label="Education Service Center Region 13 Website">Education Service Center Region 13</a> and
                    <br /><a href="http://publicimpact.com/" target="blank" aria-label="Public Impact Website">Public Impact</a>
                </p>
            </div>
            <div class="col footer d-none d-sm-block">
                <p>
                    Copyright &copy; <a href="http://www4.esc13.net/" target="blank" aria-label="Education Service Center Region 13 Website">Education Service Center Region 13</a> and
                    <a href="http://publicimpact.com/" target="blank" aria-label="Public Impact Website">Public Impact</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

    .wrapper {
        position: fixed;
        width: 100%;
        bottom: 0px;
        background-color: #191970;
        padding-bottom: 13px;
    }

    .footer {
        text-align: center;
        height: 50px;
        padding-top: 13px;
        color: #ffffff;
    }

    a:link {
        color: #00bfff;
    }

    a:visited {
        color: #b0c4de;
    }

    a:hover {
        color: #e7feff;
    }

    a:active {
        color: #a2a2d0;
    }
</style>

