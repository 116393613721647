<template>
    <div id="wrapper" v-if="false">
        <div v-if="progressPercent===100">
            <div class="col-sm-10 offset-sm-1">
                <h4 class="hdrspace vspace">Welcome to the Leadership 360 Survey Tool for {{ responderRole }}s</h4>
                <p>
                    You have already completed this survey. Thank you for taking the time to do so.
                    <br />
                    Your responses will provide valuable assistance to <strong>{{ $store.state.leaderFullName }}</strong> at <strong>{{ leaderSchool }}</strong>.
                </p>
            </div>
        </div>
        <div v-else-if="$store.state.surveyActive===false">
            <div class="col-sm-10 offset-sm-1">
                <h4 class="hdrspace vspace">Welcome to the Leadership 360 Survey Tool for {{ responderRole }}s</h4>
                <p>
                    The deadline for this survey has already expired.
                    <br />
                    Thank you for supporting the Turnaround Leader 360 Feedback Program.
                </p>
            </div>
        </div>
        <div v-else>
            <div class="row subhdr">
                <div class="col-sm-10 offset-sm-1">
                    <h4 class="hdrspace vspace">Welcome to the Leadership 360 Survey Tool for {{ responderRole }}s</h4>
                    <p>
                        This questionnaire is specifically tailored for {{ responderRole }}s
                        to provide feedback to <strong>{{ $store.state.leaderFullName }}</strong> at <strong>{{ leaderSchool }}</strong>.
                        <br />
                        The report provides a comprehensive but anonymous picture on the leader actions and competencies of <strong>{{ $store.state.leaderFullName }}</strong>.
                        <br />
                        You have been chosen to provide feedback because, as a {{ responderRole == 'leadership team' ? 'leadership team member' : responderRole }}, you are familiar with how he/she works.
                        <br />
                        In order to be most helpful to <strong>{{ $store.state.leaderFullName }}</strong>, please answer the questions that follow in a completely candid manner, without being unduly critical or uncritical.
                    </p>
                </div>
            </div>
            <br />

            <div class="row">
                <div class="col-sm-5 offset-sm-1">
                    <h5 class="hdrspace">Your Individual Reply is Confidential</h5>
                    <p>
                        Unless you are the supervisor to this individual, your reply is <strong>anonymous</strong>.
                        Questionnaire responses/scores are averaged with at least three people in your response category (e.g. teacher, leadership team).
                        Narrative comments are presented in random order to provide confidentiality and anonymity.
                    </p>
                    <p>
                        If you are the supervisor to this individual, your responses are not anonymous since supervisor responses are displayed as a separate response category.
                    </p>
                    <p>
                        If you are the subject of this questionnaire, you will assess yourself. This will provide you with a valuable point of comparison to the responses of others.
                    </p>
                    <button class="btn actionbtn startbtn" @click="StartSurvey">{{ btnText }}</button>
                </div>
                <div class="col-sm-5">
                    <img class="img-responsive" src="../assets/T360_survey.png" alt="Screen capture of a survey question page">
                </div>
            </div>
            <br />

            <div class="row subhdr">
                <div class="col-sm-10 offset-sm-1">
                    <h5 class="hdrspace vspace">How to Respond</h5>
                    <p>
                        For most questions, you will be asked to indicate the extent to which you agree or disagree that the statements are an accurate description of <strong>{{ $store.state.leaderFullName }}</strong>.
                        <br />
                        Your choice of responses ranges from a "1" (which means that you strongly disagree) to an "8" (which means that you strongly agree).
                        <br />
                        Comment boxes are provided for each question if you want to provide <strong>{{ $store.state.leaderFullName }}</strong> with more information to assist with their growth and development.
                        <br />
                        If you don't believe you have enough information to respond to a particular statement, choose "N/A - not enough information".
                    </p>
                </div>
            </div>
            <br />

            <div class="row">
                <div class="col-sm-10 offset-sm-1">
                    <h5 class="hdrspace">Providing Written Comments</h5>
                    <p>
                        You also have an opportunity to provide narrative answers to a couple of questions at the end of the questionnaire.
                        All comments will be combined with those from other respondents. They won't be linked to your name.
                        Since they will be provided verbatim, you may protect your anonymity by not writing in a recognizable style and avoiding details that are unique to you.
                    </p>
                    <hr />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-10 offset-sm-1">
                    <p>
                        <strong v-if="progressPercent < 100">
                            When you are ready to begin the survey, click on the {{ btnText }} button. You can save and exit the survey at any time and resume later.
                        </strong>
                        <br />
                        <button class="btn actionbtn" @click="StartSurvey">{{ btnText }}</button>
                    </p>
                </div>
            </div>
        </div>
        <br />
        <br />
    </div>
    <br />
</template>

<script>
    import axios from 'axios';

    export default {
        name: "SurveyInstructions",

        data() {
            return {
                leaderSchool: "",
                responderRole: "",
                progressPercent: 0
            }
        },

        methods: {
            StartSurvey() {
                this.$router.push('/survey');
            },

            GetInfo() {
                axios.get('responder/getinfo')
                .then((res) => {
                    this.$store.state.leaderFullName = res.data[0];
                    this.leaderSchool = res.data[1];
                    this.responderRole = res.data[2].toLowerCase();
                });
            },

            GetProgress() {
                axios.get('responder/getprogress')
                    .then((res) => {
                        this.progressPercent = res.data;
                    });
            }
        },

        computed: {
            btnText() {
                return (this.progressPercent == 0 ? "Start the Survey" : (this.progressPercent == 100 ? "Review the Survey" : "Continue the Survey" ));
            },
        },

        mounted() {
            document.title = "OER Texas Review";

            if (this.$store.state.loggedName == "") {
                this.$router.push('/reset');
            } else {
                this.GetInfo();
                this.GetProgress();
            }
        }
    }
</script>

<style scoped>
    .hdrspace {
        margin: 0 0 12px 6px;
        font-weight: bold;
    }

    .vspace {
        margin-top: 12px;
    }

    p {
        margin-left: 8px;
    }

    button {
        margin: 8px 8px 8px 16px;
    }

    img {
        margin-left: 40px;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding: 8px;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
    }

    .startbtn {
        margin-bottom: 25px;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>