import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Login from "@/components/Login.vue";
import Logout from "@/components/Logout.vue";
import Register from "@/components/Register.vue";
import Admin from "@/components/Admin.vue";
import Purchaser from "@/components/Purchaser.vue";
import Leader from "@/components/Leader.vue";
import Guidelines from "@/components/Guidelines.vue";
import Invite from "@/components/Invite.vue";
import SurveyInstructions from "@/components/SurveyInstructions.vue";
import Survey from "@/components/Survey.vue";
import StatusReport from "@/components/StatusReport.vue";
import Results from "@/components/Results.vue";
import About from "@/components/About.vue";
import Contact from "@/components/Contact.vue";
import Reset from "@/components/Reset.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/Login",
        name: "Login",
        component: Login,
    },
    {
        path: "/Logout",
        name: "Logout",
        component: Logout,
    },
    {
        path: "/Admin",
        name: "Admin",
        component: Admin,
    },
    {
        path: "/Purchaser",
        name: "Purchaser",
        component: Purchaser,
    },
    {
        path: "/Leader",
        name: "Leader",
        component: Leader,
    },
    {
        path: "/Register",
        name: "Register",
        component: Register,
    },  
    {
        path: "/Guidelines",
        name: "Guidelines",
        component: Guidelines,
    },
    {
        path: "/Invite",
        name: "Invite",
        component: Invite,
    },
    {
        path: "/SurveyInstructions",
        name: "SurveyInstructions",
        component: SurveyInstructions,
    },
    {
        path: "/Survey",
        name: "Survey",
        component: Survey,
    },
    {
        path: "/StatusReport",
        name: "StatusReport",
        component: StatusReport,
    },
    {
        path: "/Results",
        name: "Results",
        component: Results,
    },
    {
        path: "/About",
        name: "About",
        component: About,
    },
    {
        path: "/Contact",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/Reset",
        name: "Reset",
        component: Reset,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;