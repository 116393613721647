<template>
    <div id="wrapper" v-if="false">
        <div v-if="progressPercent===100">
            <div class="col-11 offset-1">
                <br />
                <h3><strong>Survey Conclusion</strong></h3>
                <hr />
                <h5>Thank you for taking the time to complete this questionnaire.</h5>
                <h5 v-if="this.$store.state.userRole!='Leader'">Your responses will provide valuable assistance to <strong>{{ $store.state.leaderFullName }}</strong>.</h5>
                <h5 v-if="this.$store.state.userRole!='Leader'">We are committed to keeping your responses confidential and providing them to <strong>{{ $store.state.leaderFullName }}</strong> to support his/her growth and development as a leader.</h5>
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-xs-12 col-sm-11 offset-sm-1" v-bind:class="[(this.windowWidth <= 576) ? 'xsnudge' : '']">
                    <p>
                        Please indicate the extent to which you agree or disagree that the statements which follow are an accurate description of
                        <span v-if="this.$store.state.leaderName === this.$store.state.loggedName"> you.</span>
                        <span v-else><strong>{{ $store.state.leaderFullName }}</strong>.</span>
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-4 offset-1">
                    <h4>{{ headerText }}</h4>
                </div>
                <div class="col-5 progbar">
                    <div class="d-block d-sm-none xsprog">
                       Progress: {{ progressPercent }}%
                    </div>
                    <div class="d-none d-sm-block">
                        <span v-if="this.progressPercent >= 20" class="col-1 progsect darktext shaded">*</span>
                        <span v-if="this.progressPercent >= 30" class="col-1 progsect darktext shaded">*</span>
                        <span v-if="this.progressPercent >= 40" class="col-1 progsect darktext shaded">*</span>
                        <span v-if="this.progressPercent >= 50" class="col-1 progsect darktext shaded">*</span>
                        <span v-if="this.progressPercent >= 60" class="col-1 progsect darktext shaded">*</span>
                        <span v-if="this.progressPercent >= 70" class="col-1 progsect darktext shaded">*</span>
                        <span v-if="this.progressPercent >= 80" class="col-1 progsect darktext shaded">*</span>
                        <span v-if="this.progressPercent >= 90" class="col-1 progsect darktext shaded">*</span>
                        <span v-if="this.progressPercent > 0" class="col-1 progsect lighttext shaded">{{ progressPercent }}%</span>
                    </div>
                </div>
            </div>
            <br />

            <div class="row subhdr">
                <div v-if="pagenum<10">
                    <div class="row col-11 offset-1">
                        <div class="col-2 thdr lscale">Strongly Disagree</div>
                        <div class="col-2 thdr lscale"><span class="d-none d-sm-block">Disagree</span></div>
                        <div class="col-2 thdr lscale"><span class="d-none d-sm-block">Agree</span></div>
                        <div class="col-2 thdr lscale">Strongly Agree</div>
                        <div class="col-2 lhdr lscale">Not Enough Information</div>
                    </div>
                </div>
            </div>

            <div v-if="pagenum<10" class="midsect">
                <div class="row col-11 offset-1 vpad">
                    <div class="col-1 thdr" v-for="(n, index) in 8" :key="index"><strong>{{n}}</strong></div>
                    <div class="col-1 thdr"><strong>N/A</strong></div>
                </div>

                <div class="row col-11 offset-1 qtext">
                    {{ qnums[0] }}.&nbsp; {{ qtext[0] }}
                </div>
                <div class="row col-11 offset-1">
                    <div class="col-1 thdr" v-bind:class="[q1Class]"><input type="radio" v-model="responses[0]" id="q1_1" value=1 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q1Class]"><input type="radio" v-model="responses[0]" id="q1_2" value=2 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q1Class]"><input type="radio" v-model="responses[0]" id="q1_3" value=3 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q1Class]"><input type="radio" v-model="responses[0]" id="q1_4" value=4 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q1Class]"><input type="radio" v-model="responses[0]" id="q1_5" value=5 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q1Class]"><input type="radio" v-model="responses[0]" id="q1_6" value=6 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q1Class]"><input type="radio" v-model="responses[0]" id="q1_7" value=7 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q1Class]"><input type="radio" v-model="responses[0]" id="q1_8" value=8 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q1Class]"><input type="radio" v-model="responses[0]" id="q1_9" value=9 @click="UserInput"></div>
                    <hr style="width:75%;" />
                </div>

                <div class="row col-11 offset-1 qtext">
                    {{ qnums[1] }}.&nbsp; {{ qtext[1] }}
                </div>
                <div class="row col-11 offset-1">
                    <div class="col-1 thdr" v-bind:class="[q2Class]"><input type="radio" v-model="responses[1]" id="q2_1" value=1 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q2Class]"><input type="radio" v-model="responses[1]" id="q2_2" value=2 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q2Class]"><input type="radio" v-model="responses[1]" id="q2_3" value=3 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q2Class]"><input type="radio" v-model="responses[1]" id="q2_4" value=4 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q2Class]"><input type="radio" v-model="responses[1]" id="q2_5" value=5 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q2Class]"><input type="radio" v-model="responses[1]" id="q2_6" value=6 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q2Class]"><input type="radio" v-model="responses[1]" id="q2_7" value=7 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q2Class]"><input type="radio" v-model="responses[1]" id="q2_8" value=8 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q2Class]"><input type="radio" v-model="responses[1]" id="q2_9" value=9 @click="UserInput"></div>
                    <hr style="width:75%;" />
                </div>

                <div class="row col-11 offset-1 qtext">
                    {{ qnums[2] }}.&nbsp; {{ qtext[2] }}
                </div>
                <div class="row col-11 offset-1">
                    <div class="col-1 thdr" v-bind:class="[q3Class]"><input type="radio" v-model="responses[2]" id="q3_1" value=1 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q3Class]"><input type="radio" v-model="responses[2]" id="q3_2" value=2 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q3Class]"><input type="radio" v-model="responses[2]" id="q3_3" value=3 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q3Class]"><input type="radio" v-model="responses[2]" id="q3_4" value=4 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q3Class]"><input type="radio" v-model="responses[2]" id="q3_5" value=5 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q3Class]"><input type="radio" v-model="responses[2]" id="q3_6" value=6 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q3Class]"><input type="radio" v-model="responses[2]" id="q3_7" value=7 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q3Class]"><input type="radio" v-model="responses[2]" id="q3_8" value=8 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q3Class]"><input type="radio" v-model="responses[2]" id="q3_9" value=9 @click="UserInput"></div>
                    <hr style="width:75%;" />
                </div>

                <div class="row col-11 offset-1 qtext" v-if="qtext[3]!=null">
                    {{ qnums[3] }}.&nbsp; {{ qtext[3] }}
                </div>
                <div class="row col-11 offset-1" v-if="qtext[3]!=null">
                    <div class="col-1 thdr" v-bind:class="[q4Class]"><input type="radio" v-model="responses[3]" id="q4_1" value=1 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q4Class]"><input type="radio" v-model="responses[3]" id="q4_2" value=2 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q4Class]"><input type="radio" v-model="responses[3]" id="q4_3" value=3 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q4Class]"><input type="radio" v-model="responses[3]" id="q4_4" value=4 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q4Class]"><input type="radio" v-model="responses[3]" id="q4_5" value=5 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q4Class]"><input type="radio" v-model="responses[3]" id="q4_6" value=6 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q4Class]"><input type="radio" v-model="responses[3]" id="q4_7" value=7 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q4Class]"><input type="radio" v-model="responses[3]" id="q4_8" value=8 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q4Class]"><input type="radio" v-model="responses[3]" id="q4_9" value=9 @click="UserInput"></div>
                    <hr style="width:75%;" />
                </div>

                <div class="row col-11 offset-1 qtext" v-if="qtext[4]!=null">
                    {{ qnums[4] }}.&nbsp; {{ qtext[4] }}
                </div>
                <div class="row col-11 offset-1" v-if="qtext[4]!=null">
                    <div class="col-1 thdr" v-bind:class="[q5Class]"><input type="radio" v-model="responses[4]" id="q5_1" value=1 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q5Class]"><input type="radio" v-model="responses[4]" id="q5_2" value=2 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q5Class]"><input type="radio" v-model="responses[4]" id="q5_3" value=3 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q5Class]"><input type="radio" v-model="responses[4]" id="q5_4" value=4 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q5Class]"><input type="radio" v-model="responses[4]" id="q5_5" value=5 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q5Class]"><input type="radio" v-model="responses[4]" id="q5_6" value=6 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q5Class]"><input type="radio" v-model="responses[4]" id="q5_7" value=7 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q5Class]"><input type="radio" v-model="responses[4]" id="q5_8" value=8 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q5Class]"><input type="radio" v-model="responses[4]" id="q5_9" value=9 @click="UserInput"></div>
                    <hr style="width:75%;" />
                </div>

                <div class="row col-11 offset-1 qtext" v-if="qtext[5]!=null">
                    {{ qnums[5] }}.&nbsp; {{ qtext[5] }}
                </div>
                <div class="row col-11 offset-1" v-if="qtext[5]!=null">
                    <div class="col-1 thdr" v-bind:class="[q6Class]"><input type="radio" v-model="responses[5]" id="q6_1" value=1 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q6Class]"><input type="radio" v-model="responses[5]" id="q6_2" value=2 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q6Class]"><input type="radio" v-model="responses[5]" id="q6_3" value=3 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q6Class]"><input type="radio" v-model="responses[5]" id="q6_4" value=4 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q6Class]"><input type="radio" v-model="responses[5]" id="q6_5" value=5 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q6Class]"><input type="radio" v-model="responses[5]" id="q6_6" value=6 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q6Class]"><input type="radio" v-model="responses[5]" id="q6_7" value=7 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q6Class]"><input type="radio" v-model="responses[5]" id="q6_8" value=8 @click="UserInput"></div>
                    <div class="col-1 thdr" v-bind:class="[q6Class]"><input type="radio" v-model="responses[5]" id="q6_9" value=9 @click="UserInput"></div>
                    <hr style="width:75%;" />
                </div>

                <div class="row col-11 offset-1">
                    <div class="col-1 thdr" v-for="(n, index) in 8" :key="index"><strong>{{n}}</strong></div>
                    <div class="col-1 thdr"><strong>N/A</strong></div>
                </div>

                <div class="subhdr">
                    <div class="row col-11 offset-1">
                        <div class="col-2 thdr lscale">Strongly Disagree</div>
                        <div class="col-2 thdr lscale"><span class="d-none d-sm-block">Disagree</span></div>
                        <div class="col-2 thdr lscale"><span class="d-none d-sm-block">Agree</span></div>
                        <div class="col-2 thdr lscale">Strongly Agree</div>
                        <div class="col-2 lhdr lscale">Not Enough Information</div>
                    </div>
                </div>
            </div>
           
            <div class="surveybottom">
                <br />
                <div v-if="pagenum<10" class="row col-xs-12 col-sm-11 offset-sm-1">
                    <div v-bind:class="[(this.windowWidth > 576) ? 'ta' : '']">
                        <label class="lblspace"> Please provide comments for the above questions if you have any:</label>
                        <textarea rows="5" maxlength="60000" id="comment1" class="form-control" v-model="commentText" @change="UserInput"></textarea>
                    </div>
                </div>

                <div v-if="pagenum==10">
                    <div class="row col-xs-12 col-sm-11 offset-sm-1">
                        <div v-bind:class="[(this.windowWidth > 576) ? 'ta' : '']">
                            <label class="xsnudge" v-bind:class="[n1Class]">{{ n1Text }}</label>
                            <textarea rows="5" maxlength="60000" id="narrative1" class="form-control" v-model="narrative1" @change="UserInput"></textarea>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div class="row col-xs-12 col-sm-11 offset-sm-1">
                        <div v-bind:class="[(this.windowWidth > 576) ? 'ta' : '']">
                            <label class="xsnudge" v-bind:class="[n2Class]">{{ n2Text }}</label>
                            <textarea rows="5" maxlength="60000" id="narrative2" class="form-control" v-model="narrative2" @change="UserInput"></textarea>
                        </div>
                    </div>
                    <br />
                </div>

                <div v-if="pagenum!=11">
                    <div class="row col-12 col-lg-11 ctr warntext">
                        <span v-show="skippedQuestion && (this.narrative1 == null || this.narrative1 == '' || this.narrative2 == null || this.narrative2 == '')">
                            You have skipped a required question.
                        </span>
                    </div>

                    <div class="row col-xs-12 col-sm-11 offset-sm-1 col-md-7 offset-md-3">
                        <button class="btn actionbtn" v-bind:class="[(this.windowWidth > 1800) ? 'lnudge2' : ((this.windowWidth > 1056) ? 'lnudge' : ((this.windowWidth <= 576) ? 'xsnudge2' : ''))]" 
                                v-bind:disabled='prevBtnDisabled' @click="PreviousPage">Previous</button>
                        <button class="btn actionbtn" @click="SaveResponses">Save</button>
                        <button class="btn actionbtn" @click="NextPage">{{ nextBtnText }}</button>
                    </div>
                </div>
                <div v-else>
                    <div class="row col-10 offset-2">
                        <p v-if="$store.state.userRole==='Leader'">
                            Thank you for taking the time to complete this questionnaire.
                            <br />
                            Your responses will be used in conjunction with your participants' scores in order to provide assistance with your growth and development as a leader.
                        </p>
                        <p v-else>
                            Thank you for taking the time to complete this questionnaire. Your responses will provide valuable assistance to <strong>{{ $store.state.leaderFullName }}</strong> at <strong>{{ leaderSchool }}</strong>.
                            <br />
                            We are committed to keeping your responses confidential and providing them to <strong>{{ $store.state.leaderFullName }}</strong> to support his/her growth and development as a leader.
                        </p>
                    </div>
                    <div class="row col-12 decobar"></div>
                </div>
            </div>
        </div>
        <br />
        <br />
    </div>
    <br />
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Survey",

        data() {
            return {
                qnum: 1,
                pagenum: 1,
                qtext: [],
                responses: [0, 0, 0, 0, 0],
                prevBtnEnabled: false,
                commentText: "",
                dataChangedByUser: false,
                skippedQuestion: false,
                nextBtnText: "Next",
                headerText: "My Leader / Principal ...",
                narrative1: "",
                narrative2: "",
                leaderSchool: "",
                assessmentId: 0,
                progressPercent: 0,
                qnums: [],
                windowWidth: 0,
                submitted: false
            }
        },

        methods: {
            GetAssessment() {
                axios.get('responder/getassessment')
                    .then((res) => {
                        this.assessmentId = res.data[0];
                        this.pagenum = res.data[1];
                        if (this.pagenum == 10) {
                            this.GetNarrative();
                        } else {
                            this.GetQuestions();
                            this.GetAnswers();
                        }
                        this.GetProgress();
                        this.EnableButtons();
                    });
            },

            GetQuestions() {
                axios.get('responder/getquestions', { params: { pageNum: this.pagenum }})
                    .then((res) => {
                        for (var i = 0; i < 6; i++) {
                            this.qtext[i] = res.data[i];
                            this.qnums[i] = res.data[i + 6];
                        }
                    });
            },

            GetAnswers() {
                this.dataChangedByUser = false;
                this.skippedQuestion = false;
                axios.get('responder/getanswers', { params: { assessmentId: this.assessmentId, pageNum: this.pagenum }})
                    .then((res) => {
                        for (var i = 0; i < 6; i++) {
                            this.responses[i] = res.data[i];
                        }
                        this.commentText = res.data[6];
                    });
            },

            GetNarrative() {
                this.dataChangedByUser = false;
                this.skippedQuestion = false;
                axios.get('responder/getnarrative', { params: { assessmentId: this.assessmentId }})
                    .then((res) => {
                        this.narrative1 = res.data[0];
                        this.narrative2 = res.data[1];
                    });
            },

            GetProgress() {
                axios.get('responder/getprogress', { params: { assessmentId: this.assessmentId, surveySubmitted: this.submitted }})
                    .then((res) => {
                        this.progressPercent = res.data;
                    });
            },

            PreviousPage() {
                if (this.pagenum === 10) {
                    this.RecordNarrative();
                } else {
                    this.RecordResponses();
                }
                if (this.pagenum > 1) {
                    this.pagenum--;
                    this.GetQuestions();
                    this.GetAnswers();
                    this.EnableButtons();
                }
            },

            NextPage() {
                if (this.pagenum === 10) {
                    this.FinishSurvey();
                } else {
                    if (this.AllQuestionsAnswered()) {
                        this.RecordResponses();
                        this.pagenum++;                      
                        if (this.pagenum === 10) {                           
                            this.GetNarrative();
                        } else {
                            this.GetQuestions();
                            this.GetAnswers();
                        }                       
                        this.EnableButtons();
                    }
                }             
            },

            SaveResponses() {
                if (this.pagenum === 10) {
                    this.RecordNarrative();
                    alert("Your progress has been saved.");
                } else {
                    this.RecordResponses();
                    alert("Your progress has been saved.");
                }
            },

            AllQuestionsAnswered() {
                for (var i = 0; i < 6; i++) {
                    if (this.responses[i] === null || this.responses[i] === 0) {
                        if (i < 3) {  // All quesions must be answered and there are at least 3 questions on each page
                            this.skippedQuestion = true;
                            return false;
                        } else {
                            if (this.qtext[i] != null) {
                                this.skippedQuestion = true;
                                return false;
                            }
                        }
                    }
                }
                return true;              
            },

            RecordNarrative() {
                var postData = { "AssessmentId": this.assessmentId, "Narrative1": this.narrative1, "Narrative2": this.narrative2, "SurveySubmitted": this.submitted };
                axios.post('responder/recordnarrative', postData)
                    .then(() => {
                    });
            },

            RecordResponses() {
                if (this.dataChangedByUser) {  // only record responses if user actually changed something
                    for (var i = 0; i < 6; i++) {
                        this.responses[i] = this.responses[i] * 1; // make sure entire array is numeric
                    }
                    var postData = {
                        "AssessmentId": this.assessmentId, "PageNum": this.pagenum,
                        "Answers": this.responses, "CommentText": this.commentText, "SurveySubmitted": this.submitted 
                    };
                    axios.post('responder/recordresponses', postData)
                        .then((res) => {
                            this.progressPercent = res.data;
                        });
                }
            },

            FinishSurvey() {
                if (this.narrative1 == null || this.narrative1 == "" || this.narrative2 == null || this.narrative2 == "") {
                    this.skippedQuestion = true;
                } else {
                    this.submitted = true;
                }

                this.RecordNarrative();

                if (this.submitted == true) {
                    axios.get('responder/getinfo')
                        .then((res) => {
                            this.$store.state.leaderFullName = res.data[0];
                            this.leaderSchool = res.data[1];
                            this.headerText = "Survey Conclusion";
                            this.pagenum = 11; // displays concluding text
                            this.progressPercent = 100;
                        });
                }
            },

            EnableButtons() {
                if (this.pagenum > 1) {
                    this.prevBtnEnabled = true;
                } else {
                    this.prevBtnEnabled = false;
                }

                if (this.pagenum === 10) {
                    this.nextBtnText = "Submit";
                    this.headerText = "Narrative Questions"
                } else {
                    this.nextBtnText = "Next";
                    this.headerText = "My Leader / Principal ...";
                }
            },

            UserInput() {
                this.dataChangedByUser = true;
            }
        },

        computed: {
            prevBtnDisabled() {
                return !this.prevBtnEnabled;
            },

            q1Class() {
                return (this.skippedQuestion == true && (this.responses[0] == null || this.responses[0] == "0")) ? 'warncol' : 'darkcol';
            },

            q2Class() {
                return (this.skippedQuestion == true && (this.responses[1] == null || this.responses[1] == 0)) ? 'warncol' : 'darkcol';
            },

            q3Class() {
                return (this.skippedQuestion == true && (this.responses[2] == null || this.responses[2] == 0)) ? 'warncol' : 'darkcol';
            },

            q4Class() {
                return (this.skippedQuestion == true && (this.responses[3] == null || this.responses[3] == 0)) ? 'warncol' : 'darkcol';
            },

            q5Class() {
                return (this.skippedQuestion == true && (this.responses[4] == null || this.responses[4] == 0)) ? 'warncol' : 'darkcol';
            },

            q6Class() {
                return (this.skippedQuestion == true && (this.responses[5] == null || this.responses[5] == 0)) ? 'warncol' : 'darkcol';
            },

            n1Class() {
                return (this.skippedQuestion == true && (this.narrative1 == null || this.narrative1 == '')) ? 'warntext' : '';
            },

            n2Class() {
                return (this.skippedQuestion == true && (this.narrative2 == null || this.narrative2 == '')) ? 'warntext' : '';
            },

            n1Text() {
                return (this.$store.state.userRole === 'Leader' ? 'List three critical strengths that you believe you bring to school leadership:' : 'List three critical strengths that you think this person brings to school leadership:');
            },

            n2Text() {
                return (this.$store.state.userRole === 'Leader' ? 'List three ways you think you can improve your performance as a school leader:' : 'List three ways you think this person can improve their performance as a school leader:');
            }
        },

        mounted() {
            document.title = "OER Texas Review";

            this.windowWidth = window.innerWidth;

            window.onresize = () => {
                this.windowWidth = window.innerWidth;
            }

            if (this.$store.state.loggedName == "") {
                this.$router.push('/reset');
            } else {
                this.GetAssessment();
            }
        }
    }
</script>

<style scoped>
    button {
        display:block; 
        margin: 0 auto;
    }

    .ctr {
        text-align: center;
    }

    .thdr {
        text-align: center;
        margin-bottom: 12px;
    }

    .lhdr {
        margin-bottom: 12px;
        margin-left: 8px;
    }

    .qtext {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .lscale {
        font-size: 1.1em;
    }

    .darkcol {
        background-color: #bcd4e6;
    }

    .warncol {
        background-color: #e60000;
    }

    button {
        margin: 28px 20px;
        width: 90px;
        height: 45px;
        float: left;
    }

    .ta {
        width: 80%;
    }

    .warntext {
        color: #e60000;
        margin-top: 12px;
    }

    .xsprog {
        float: right;
    }

    .xsnudge {
        margin-left: 10px;
    }

    .xsnudge2 {
        margin-left: 45px;
    }

    .snudge {
        margin-left: 25px;
    }

    .lnudge {
        margin-left: 150px;
    }

    .lnudge2 {
        margin-left: 200px;
    }

    .lblspace {
        margin-bottom: 5px;
        margin-left: 8px;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
    }

    .subhdr {
        background-color: #427aa1;
        padding-top: 8px;
        color: #ffffff;
    }

    .midsect {
        background-color: #f0f0f5;
    }

    .surveybottom {
        background-color: #e1e9ed;
        margin-top: 0;
    }

    .vpad {
        padding-top: 10px;
    }

    .progbar {
        height: 25%;
        font-size: .8em;
    }

    .progsect {
        display: inline-block;
        width: 10%;
        text-align: right;
        padding-right: 12px;
    }

    .darktext {
        color: #191970;
    }

    .lighttext {
        color: #ffffff;
    }

    .shaded {
        background-color: #191970;
    }

    .decobar {
        background-color: #427aa1;
        height: 8px;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>