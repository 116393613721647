<template>
    <div id="wrapper" v-if="false">
        <div class="subhdr">
            <br />
            <h1 class="ctr">Purchaser Dashboard</h1>
            <div class="row" v-if="sessionList.length > 1">
                <div class="col-6">
                    <span class="viewlbl"><strong>Current Session:</strong></span>
                </div>
                <div class="col-6">
                    {{ currentSession }}
                </div>
            </div>
            <div class="row" v-if="sessionList.length > 1">
                <div class="col-6">
                    <span class="viewlbl"><strong>Viewing Session:</strong></span>
                </div>
                <div class="col-6">
                    <select class="form-select dropdown" aria-label="Session" v-model="$store.state.viewSession" v-on:change="GetSingleSession()">
                        <option v-for="session in sessionList" v-bind:key="session">{{ session }}</option>
                    </select>
                </div>
            </div>
            <br />

            <div class="row">
                <div class="ctr hdrline">
                    <span v-if="$store.state.viewSession===currentSession">Of the {{ seats }} seats purchased, you have {{ seats - leaderCount }} seats remaining.</span>
                    <span v-else style="margin-left:90px;"></span>
                </div>
            </div>
            <div class="row">
                <div class="ctr">
                    <button class="btn btn-large actionbtn" @click="FillSeat" v-show="$store.state.viewSession===currentSession && seats>leaderCount">Fill Seat</button>
                    <button class="btn btn-large actionbtn statusbtn" @click="StatusReport">Status Report (All Leaders)</button>
                </div>
            </div>
            <div class="row d-none d-lg-block">
                <div class="col-xs-2 offset-8">
                    Download the <strong><span class="clickable underline" @click="GetFile('T360_Facilitator_Guide.pdf')">Facilitator Guide</span></strong>.
                </div>
            </div>
            <div class="row d-block d-lg-none">
                <div class="ctr">
                    Download the <strong><span class="clickable underline" @click="GetFile('T360_Facilitator_Guide.pdf')">Facilitator Guide</span></strong>.
                </div>
            </div>
        </div>
        <br />

        <div class="row newldr" v-if="showLeaderForm">
            <div class="col-8 col-sm-6 col-lg-4 offset-1 offset-sm-3 offset-md-3 offset-lg-4">
                <form method="post">
                    <div class="form-group form-inline">
                        <label for="FirstName" class="createlbl">Create a new leader account:</label>
                        <input name="FirstName" type="text" v-model="firstname" class="form-control" placeholder="First Name" aria-label="First Name"
                               v-bind:class="[(this.leaderFail && this.firstname=='') ? 'warnstyle' : 'normalstyle']" />
                        <input name="LastName" type="text" v-model="lastname" class="form-control" placeholder="Last Name" aria-label="Last Name"
                               v-bind:class="[(this.leaderFail && this.lastname=='') ? 'warnstyle' : 'normalstyle']" />
                        <input name="CreateEmail" type="text" v-model="createemail" class="form-control" placeholder="Email" aria-label="Email Address"
                               v-bind:class="[(this.leaderFail && this.createemail=='') ? 'warnstyle' : 'normalstyle']" />
                        <input name="School" type="text" v-model="school" class="form-control" placeholder="School" aria-label="School"
                               v-bind:class="[(this.leaderFail && this.school=='') ? 'warnstyle' : 'normalstyle']" />
                        <input name="District" type="text" v-model="district" class="form-control" placeholder="District" aria-label="District"
                               v-bind:class="[(this.leaderFail && this.district=='') ? 'warnstyle' : 'normalstyle']" />

                        <StateList v-model="selectedState" v-bind:class="[(this.leaderFail && this.selectedState==='NS') ? 'warnstyle' : 'normalstyle']"></StateList>
                    </div>
                    <br />
                    <p class="ctr"><button type="submit" class="btn btn-large actionbtn" @click.prevent="CreateLeader">Create</button></p>
                </form>
                <p class="ctr" v-bind:class="[(this.leaderFail==true) ? 'warning' : 'notice']">{{ leaderMessage }}</p>
            </div>
        </div>

        <p class="ctr" v-show="actionMessage.length>0" v-bind:class="[actionFail ? 'warning' : 'notice']">{{ actionMessage }}</p>

        <div class="row">
            <div v-for="school in schools" :key="school.value">
                <div class="col-sm-10 offset-sm-1 userlist">
                    <h5 class="schoolhdr">{{ school }}</h5>
                    <div class="col-sm-11 offset-sm-1" v-bind:class="[(this.windowWidth <= 1000) ? 'table-responsive' : '']">
                        <table class="table" v-bind:class="[(this.windowWidth <= 1000) ? 'smalltable' : '']">
                            <colgroup>
                                <col style="width:20%">
                                <col style="width:12%">
                                <col style="width:23%">
                                <col style="width:15%">
                                <col style="width:30%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Leader</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Account Created</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="leader in leaders" :key="leader.username" v-show="leader.school==school">
                                    <td>{{ leader.fullName }}</td>
                                    <td class="userselect" @click="BecomeLeader(leader.username, leader.fullName)">{{ leader.username }}</td>
                                    <td>{{ leader.email }}</td>
                                    <td>{{ leader.dateCreated }}</td>
                                    <td>
                                        <button class="btn actionbtn" v-scroll-to="{el: '#pwdBox', duration: 100}" @click="PwdVerify(leader.username, leader.fullName)"
                                                v-show="$store.state.viewSession===currentSession">
                                            Reset Password
                                        </button>
                                        <button class="btn actionbtn" v-scroll-to="{el: '#forceBox', duration: 100}" @click="ForceVerify(leader.username, leader.fullName)">Force Report</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div id="pwdBox" class="row modalbox" v-if="showReset">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>Reset the password for {{ activeFullName }}?</p>
                <button class="btn btn-yes verifybtn" @click="ResetPwd">Yes</button>
                <button class="btn btn-no verifybtn" @click="showReset=false">Cancel</button>
            </div>
        </div>

        <div id="forceBox" class="row modalbox" v-if="showForce">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>Are you sure you want to force the report for {{ activeFullName }}?</p>
                <button class="btn btn-yes verifybtn" @click="ForceReport">Yes</button>
                <button class="btn btn-no verifybtn" @click="showForce=false">Cancel</button>
            </div>
        </div>

        <p class="ctr" v-show="actionMessage.length>0" v-bind:class="[actionFail ? 'warning' : 'notice']">{{ actionMessage }}</p>
        <br />
        <br />
    </div>
    <br />
</template>

<script>
    import axios from 'axios';
    import StateList from './StateList.vue';

    export default {
        name: "Purchaser",
        data() {
            return {
                currentSession: "",
                session: "",
                sessionList: [],
                seats: 0,
                firstname: "",
                lastname: "",
                createemail: "",
                school: "",
                district: "",
                selectedState: "NS",
                leaders: [],
                leaderCount: 0,
                schools: [],
                activeUserName: "",
                activeFullName: "",
                showReset: false,
                showForce: false,
                showLeaderForm: false,
                leaderFail: false,
                leaderMessage: "",
                actionMessage: "",
                actionFail: false,
                windowWidth: 0
            }
        },

        components: {
            StateList
        },

        methods: {
            GetSessions() {
                axios.get('purchaser/getsessions', { params: { purchaserName: this.$store.state.purchaserAlias }})
                    .then((res) => {
                        this.sessionList = res.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            GetSingleSession() {
                this.ClearNotices();
                this.showLeaderForm = false;
                this.GetLeaderInfo();
            },

            GetLeaderInfo() {
                axios.get('purchaser/getsinglesession', { params: { purchaserName: this.$store.state.purchaserAlias, viewSession: this.$store.state.viewSession }})
                    .then((res) => {
                        this.seats = res.data[0];
                        this.leaderCount = res.data[1];
                        this.leaders = res.data[2];
                        this.schools = res.data[3];
                        this.currentSession = res.data[4];
                        if (this.$store.state.viewSession == "") {
                            this.$store.state.viewSession = this.currentSession;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            FillSeat() {              
                this.showLeaderForm = !this.showLeaderForm;
                this.ClearNotices();
            },

            CreateLeader() {
                this.ClearNotices();
                if (this.ValidateInput() === true) {
                    var postData = {
                        Role: "Leader", "Email": this.createemail, "FirstName": this.firstname, "LastName": this.lastname,
                        "School": this.school, "District": this.district, "State": this.selectedState, "PurchaserName": this.$store.state.purchaserAlias
                    };
                    axios.post('account/createleader', postData)
                        .then(() => {
                            this.leaderFail = false;
                            this.leaderMessage = "Leader created successfully.";
                            this.GetLeaderInfo();
                            this.ClearCreateLeader();
                        })
                        .catch((error) => {
                            this.leaderFail = true;
                            this.leaderMessage = error.response.data;
                        });
                }
            },

            PwdVerify(username, fullName) {
                this.ClearNotices();
                this.activeUsername = username;
                this.activeFullName = fullName;
                this.showReset = true;
                this.showLeaderForm = false;
            },

            ResetPwd() {
                this.ClearNotices();
                var postData = { Username: this.activeUsername };
                axios.post('account/resetpassword', postData)
                    .then(() => {
                        this.actionMessage = this.activeFullName + "'s password has been reset.";
                        this.showReset = false;
                    })
                    .catch((error) => {
                        this.actionFail = true;
                        this.actionMessage = error.response.data;
                    });
            },

            ForceVerify(username, fullName) {
                this.ClearNotices();
                this.activeUsername = username;
                this.activeFullName = fullName;
                this.showForce = true;
                this.showLeaderForm = false;
            },

            ForceReport() {
                this.ClearNotices();
                var postData = { "Username": this.activeUsername, "purchaserName": this.$store.state.purchaserAlias  };
                axios.post('admin/forcereport', postData)
                    .then(() => {
                        this.actionMessage = this.activeFullName + "'s report has been forced.";
                        this.showForce = false;
                    })
                    .catch((error) => {
                        this.actionMessage = error.response.data;
                        this.actionFail = true;
                    });
            },

            ClearNotices() {
                this.actionFail = false;
                this.actionMessage = "";
                this.leaderFail = false;
                this.leaderMessage = "";
                this.showForce = false;
                this.showReset = false;
            },

            ClearCreateLeader() {
                this.createemail = "";
                this.firstname = "";
                this.lastname = "";
                this.school = "";
                this.district = "";
                this.selectedState = "NS";
            },

            StatusReport() {
                this.$router.push('/statusReport');
            },

            BecomeLeader(username, fullName) {
                this.$store.state.leaderAlias = username;
                this.$store.state.leaderFullName = fullName;
                this.$router.push('/leader');
            },

            ValidateInput() {
                if (this.firstname == "") {
                    this.leaderMessage = "You must input a first name.";
                    this.leaderFail = true;
                    return false;
                }

                if (this.lastname == "") {
                    this.leaderMessage = "You must input a last name.";
                    this.leaderFail = true;
                    return false;
                }

                if (this.createemail == "") {
                    this.leaderMessage = "You must input a valid email address.";
                    this.leaderFail = true;
                    return false;
                }

                if (this.school == "") {
                    this.leaderMessage = "You must input a school.";
                    this.leaderFail = true;
                    return false;
                }

                if (this.district == "") {
                    this.leaderMessage = "You must input a district.";
                    this.leaderFail = true;
                    return false;
                }

                if (this.selectedState === "NS" || this.selectedState == "") {
                    this.leaderMessage = "You must select a state.";
                    this.leaderFail = true;
                    return false;
                }

                this.leaderMessage = "";
                this.leaderFail = false;
                return true;
            },

            GetFile(fileName) {
                this.showMessage = false;
                this.error = false;
                axios({
                    url: 'api/content/getfile',
                    method: 'GET',
                    params: { "fileName": fileName },
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                });
            }
        },

        mounted() {
            document.title = "OER Texas Review";

            this.windowWidth = window.innerWidth;

            window.onresize = () => {
                this.windowWidth = window.innerWidth;
            }

            if (this.$store.state.loggedName == "") {
                this.$router.push('/reset');
            } else {
                this.GetSessions();
                this.GetSingleSession();
            }
        }
    }
</script>

<style scoped>
    .ctr {
        text-align: center;
    }

    .hdrline {
        margin-bottom: 5px;
    }

    input {
        margin-bottom: 8px;
    }

    .warning {
        color: #e60000;
    }

    .notice {
        color: #0d6efd;
    }

    .normalstyle {
        border-color: #808080;
    }

    .warnstyle {
        border-color: #e60000;
    }

    .schoolhdr {
        background-color: #003399;
        color: #ffffff;
        padding: 5px;
    }

    .actionbtn {
        margin-left: 16px;
        margin-bottom: 8px;
        width: 150px;
    }

    .dropdown {
        width: 120px;
    }

    .viewlbl {
        float:right;
        margin-bottom: 4px;
    }

    .buffer {
        margin-bottom: 10px;
    }

    .subhdr {
        background-color: #d1d1e0;
    }

    .createlbl {
        margin-bottom: 6px;
        font-weight: bold;
    }

    .newldr {
        background-color: #e1e9ed;
        margin-bottom: 12px;
        padding-top: 8px;
    }

    .verifybtn {
        width: 75px;
        margin-left: 10px;
        margin-bottom: 8px;
    }

    .btn-yes {
        background-color: #33adff;
        color: #ffffff;
    }

    .btn-no {
        background-color: #850021;
        color: #ffffff;
    }

    .verifybox {
        border: 5px solid #000099;
        padding: 10px;
        background-color: #e6f7ff;
    }

    .modalbox {
        margin-bottom: 20px;
    }

    .userlist {
        background-color: #cce0ea;
    }

    .userselect {
        color: #0039e6;
        cursor: pointer;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
    }

    .statusbtn {
        width: 225px;
    }

    .smalltable {
        width: 850px;
    }

    .underline {
        text-decoration: underline;
    }

    .clickable {
        cursor: pointer;
        color: #003399;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>