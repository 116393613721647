<template>
    <div id="wrapper" v-if="false">
        <div class="subhdr text-center">
            <h1 class="ctr">Dashboard for {{ $store.state.leaderFullName }}</h1>
            <br />
            <div class="row">
                <div class="text-center">
                    <button class="btn btn-lg actionbtn hdrbtn" v-show="$store.state.leaderAlias=='' && $store.state.surveyActive===true && progressPercent < 100"
                            @click="SelfSurvey">
                        Self Survey
                    </button>
                    <button v-show="reportReady" class="btn btn-lg actionbtn centerbtn hdrbtn" @click="ViewReport">View Report</button>
                    <button class="btn btn-lg actionbtn hdrbtn" @click=" EditResponders">Edit Responders</button>
                </div>
                <div class="dwnld text-center">
                    Download the <strong><span class="clickable underline" @click="GetFile('ParticipantWorkbook.pdf')">Participant Workbook</span></strong>.
                    <br />
                    Download the <strong><span class="clickable underline" @click="GetFile('email_templates.pdf')">Email Templates</span></strong>.
                </div>
            </div>
        </div>
        <br />

        <div class="row" v-if="surveysFinished===false">
            <div class="col-12 compwarn">
                <InformationCircleIcon class="icon" />
                <span><strong>&nbsp;&nbsp; Your survey has not been completed by all suggested participants.</strong> See below for details.</span>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-8 offset-sm-2 table-responsive">
                <h4 style="margin-left: 6px;">Existing Surveys</h4>
                <table class="table">
                    <colgroup>
                        <col style="width:31%">
                        <col style="width:15%">
                        <col style="width:12%">
                        <col style="width:12%">
                        <col style="width:15%">
                        <col style="width:15%">
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col">Participants</th>
                            <th scope="col" class="ctr">% Complete</th>
                            <th scope="col">Actions</th>
                            <th scope="col"></th>
                            <th scope="col">Last Emailed</th>
                            <th scope="col">Survey End</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tblhdr">
                            <th>Leader</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr v-for="survey in leaderSurveys" :key="survey.Username">
                            <td>{{ survey.fullName }} &nbsp;&nbsp;&nbsp;&nbsp;<em>{{ survey.email }}</em></td>
                            <td class="ctr">{{ Math.round(survey.progressPercent) }}%</td>
                            <td>
                                <button class="btn actionbtn btnaction" v-if="progressPercent < 100 && $store.state.surveyActive===true && $store.state.leaderAlias==''"
                                        @click="SelfSurvey">
                                    Self Survey
                                </button>
                            </td>
                            <td>
                                <button class="btn actionbtn btnaction" v-scroll-to="{el: '#inviteBox', duration: 100}"
                                        @click="InviteVerify(survey.username, survey.fullName, 'leader')" v-if="$store.state.leaderAlias!='' && survey.progressPercent < 100">
                                    Extend Survey
                                </button>
                            </td>
                            <td>{{ survey.lastEmailed }}</td>
                            <td>{{ survey.surveyEnd }}</td>
                        </tr>
                        <tr v-if="leaderSurveys.length==0" class="nousers">
                            <td>There are no leaders assigned.</td>
                        </tr>
                        <tr class="tblhdr">
                            <th>Supervisor</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr v-for="survey in supervisorSurveys" :key="survey.username">
                            <td>{{ survey.fullName }} &nbsp;<em>{{ survey.email }}</em></td>
                            <td class="ctr">{{ Math.round(survey.progressPercent) }}%</td>
                            <td>
                                <button class="btn actionbtn btnaction resendBtn" v-scroll-to="{el: '#inviteBox', duration: 100}"
                                        @click="InviteVerify(survey.username, survey.fullName, 'supervisor')" v-if="survey.progressPercent < 100">
                                    Resend Invite
                                </button>
                            </td>
                            <td>
                                <button class="btn actionbtn btnaction resetBtn" v-scroll-to="{el: '#pwdBox', duration: 100}"
                                        @click="PwdVerify(survey.username, survey.fullName)" v-if="survey.progressPercent < 100">
                                    Reset Password
                                </button>
                            </td>
                            <td>{{ survey.lastEmailed }}</td>
                            <td>{{ survey.surveyEnd }}</td>
                        </tr>
                        <tr v-if="supervisorSurveys.length==0" class="nousers">
                            <td>There are no supervisors assigned.</td>
                        </tr>
                        <tr class="tblhdr">
                            <th>Teachers and Staff</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr v-for="survey in teacherSurveys" :key="survey.username">
                            <td>{{ survey.fullName }} &nbsp;<em>{{ survey.email }}</em></td>
                            <td class="ctr">{{ Math.round(survey.progressPercent) }}%</td>
                            <td>
                                <button class="btn actionbtn btnaction resendBtn" v-scroll-to="{el: '#inviteBox', duration: 100}"
                                        @click="InviteVerify(survey.username, survey.fullName, 'teacher')" v-if="survey.progressPercent < 100">
                                    Resend Invite
                                </button>
                            </td>
                            <td>
                                <button class="btn actionbtn btnaction resetBtn" v-scroll-to="{el: '#pwdBox', duration: 100}"
                                        @click="PwdVerify(survey.username, survey.fullName)" v-if="survey.progressPercent < 100">
                                    Reset Password
                                </button>
                            </td>
                            <td>{{ survey.lastEmailed }}</td>
                            <td>{{ survey.surveyEnd }}</td>
                        </tr>
                        <tr v-if="teacherSurveys.length==0" class="nousers">
                            <td>There are no teachers assigned.</td>
                        </tr>
                        <tr class="tblhdr">
                            <th>Leadership Team Members</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr v-for="survey in leadershipSurveys" :key="survey.username">
                            <td>{{ survey.fullName }} &nbsp;<em>{{ survey.email }}</em></td>
                            <td class="ctr">{{ Math.round(survey.progressPercent) }}%</td>
                            <td>
                                <button class="btn actionbtn btnaction resendBtn" v-scroll-to="{el: '#inviteBox', duration: 100}"
                                        @click="InviteVerify(survey.username, survey.fullName, 'leadership')" v-if="survey.progressPercent < 100">
                                    Resend Invite
                                </button>
                            </td>
                            <td>
                                <button class="btn actionbtn btnaction resetBtn" v-scroll-to="{el: '#pwdBox', duration: 100}"
                                        @click="PwdVerify(survey.username, survey.fullName)" v-if="survey.progressPercent < 100">
                                    Reset Password
                                </button>
                            </td>
                            <td>{{ survey.lastEmailed }}</td>
                            <td>{{ survey.surveyEnd }}</td>
                        </tr>
                        <tr v-if="leadershipSurveys.length==0" class="nousers">
                            <td>There are no leadership team members assigned.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4 offset-sm-5">
                <p v-bind:class="[(this.error==true) ? 'warning' : 'notice']" v-show="showMessage">{{ message }}</p>
            </div>
        </div>

        <div id="pwdBox" class="row modalbox" v-if="showReset">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>Reset the password for {{ activeFullName }}?</p>
                <button class="btn btn-yes verifybtn" @click="ResetPwd">Yes</button>
                <button class="btn btn-no verifybtn" @click="showReset=false">Cancel</button>
            </div>
        </div>

        <div id="inviteBox" class="row modalbox" v-if="showInvite">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>{{ inviteText }} {{ activeFullName }}?</p>
                <button class="btn btn-yes verifybtn" @click="ResendInvite">Yes</button>
                <button class="btn btn-no verifybtn" @click="showInvite=false">Cancel</button>
            </div>
        </div>
        <br />
        <br />
    </div>
</template>


<script>
    import axios from 'axios';
    import { InformationCircleIcon } from '@heroicons/vue/solid';

    export default {
        name: "Leader",

        components: {
            InformationCircleIcon
        },

        data() {
            return {
                showMessage: false,
                message: "",
                reportReady: false,
                surveysFinished: true,
                progressPercent: 0,
                error: false,
                activeUsername: "",
                activeFullName: "",
                showReset: false,
                showInvite: false,
                leaderName: "",
                leaderFullName: "",
                leaderAlias: "",
                inviteText: "Resend invite and extend survey window for",
                resetBtnHeight: 30
            }
        },

        methods: {
            GetSurveys() {
                this.error = false;
                axios.get('leader/getsurveys', {
                    params: {
                        leaderName: this.$store.state.leaderAlias == "" ? this.$store.state.leaderName : this.$store.state.leaderAlias }
                })
                    .then((res) => {
                        this.$store.state.surveys = res.data;
                        if (this.$store.state.surveys.length > 1) { // If no one is invited yet, don't display survey incomplete warning
                            for (var i = 0; i < this.$store.state.surveys.length; i++) {
                                if (this.$store.state.surveys[i].progressPercent < 100) {
                                    this.surveysFinished = false;
                                    break;
                                }
                            }
                        }
                    });
            },

            EditResponders() {
                this.$router.push('/guidelines');
            },

            SelfSurvey() {
                this.$router.push('/survey');
            },

            ViewReport() {
                this.$router.push('/results');
            },

            GetFile(fileName) {
                this.showMessage = false;
                this.error = false;
                axios({
                    url: 'api/content/getfile',
                    method: 'GET',
                    params: { "fileName": fileName },
                    responseType: 'blob'
                }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();                  
                });
            },

            InviteVerify(username, fullName, role) {
                this.showMessage = false;
                this.error = false;
                this.activeUsername = username;
                this.activeFullName = fullName;
                if (role === "leader") {
                    this.inviteText = "Extend survey window for";   // There is an artificial dependency on this text in ResendInvite.
                } else {                                           // If updating this text, be sure to modify that function as well.
                    this.inviteText = "Resend invite and extend survey window for";
                }                                           
                this.showInvite = true;
            },

            ResendInvite() {
                var postData = { "Username": this.activeUsername };
                axios.post('account/resendinvite', postData)
                    .then(() => {
                        this.showMessage = true;
                        if (this.inviteText == "Extend survey window for") {
                            this.message = this.activeFullName + "'s window has been extended.";
                        } else {
                            this.message = "A reminder has been emailed to " + this.activeFullName + ".";
                        }
                        this.showInvite = false;
                        this.GetSurveys();
                    })
                    .catch((error) => {
                        this.showMessage = true;
                        this.error = true;
                        this.message = error.response.data;
                    });
            },

            PwdVerify(username, fullName) {
                this.showMessage = false;
                this.error = false;
                this.activeUsername = username;
                this.activeFullName = fullName;
                this.showReset = true;
            },

            ResetPwd() {
                var postData = { "Username": this.activeUsername };
                axios.post('account/resetpassword', postData)
                    .then(() => {
                        this.showMessage = true;
                        this.message = this.activeFullName + "'s password has been reset.";
                        this.showReset = false;
                    })
                    .catch((error) => {
                        this.showMessage = true;
                        this.error = true;
                        this.message = error.response.data;
                    });
            },

            GetProgress() {
                axios.get('leader/getprogress')
                    .then((res) => {
                        this.progressPercent = res.data;
                    });
            },

            CheckIfReportIsReady() {
                this.showMessage = false;
                this.error = false;
                axios.get('leader/rptreadycheck', {
                    params: { leaderName: this.$store.state.leaderAlias == "" ? this.$store.state.leaderName : this.$store.state.leaderAlias }
                    })
                    .then((res) => {
                        this.reportReady = res.data;
                    })
                    .catch((error) => {
                        this.showMessage = true;
                        this.error = true;
                        this.message = error.response.data;
                    });
            },

            GetFullName() {
                axios.get('account/getfullname', { params: { searchUser: this.$store.state.loggedName }})
                    .then((res) => {
                        this.$store.state.leaderFullName = res.data;
                    });
            },

            ResizeButtons() { // On mid-size screens, sometimes resend buttons are 30px high while reset buttons are 48px. This function makes them the same.
                let resetButtons = document.getElementsByClassName('resetBtn');
                if (resetButtons.length > 0) {
                    this.resetBtnHeight = resetButtons[0].clientHeight;
                    let resendButtons = document.getElementsByClassName('resendBtn');
                    for (let i = 0; i < resendButtons.length; i++) {
                        resendButtons[i].style.height = this.resetBtnHeight + 'px';
                    }
                }
            }
        },

        computed: {
            leaderSurveys() {
                return this.$store.state.surveys.filter(survey => survey.role === 'Leader');
            },

            supervisorSurveys() {
                return this.$store.state.surveys.filter(survey => survey.role === 'Supervisor');
            },

            teacherSurveys() {
                return this.$store.state.surveys.filter(survey => survey.role === 'Teacher');
            },

            leadershipSurveys() {
                return this.$store.state.surveys.filter(survey => survey.role === 'Leadership Team');
            }
        },

        mounted() {
            document.title = "OER Texas Review";

            setTimeout(() => {
                this.ResizeButtons();
            }, 250) // Give DOM time to render so we can get button height

            window.onresize = () => {
                this.ResizeButtons();
            }

            if (this.$store.state.loggedName == "") {
                this.$router.push('/reset');
            } else {
                this.GetSurveys();
                if (this.$store.state.leaderAlias == "") {
                    this.GetProgress();
                    this.GetFullName();
                }
                this.CheckIfReportIsReady();
            }
        }
    }
</script>

<style scoped>
    .ctr {
        text-align: center;
    }

    .underline {
        text-decoration: underline;
    }

    .tblhdr {
        background-color: #003399;
        color: #ffffff;
    }

    .clickable {
        cursor: pointer;
        color: #003399;
    }

    .dwnld {
        float: right;
        margin-top: 10px;
    }

    .centerbtn {
        margin: 0 15px;
    }

    .hdrbtn {
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .btnaction {
        font-size: 12px;
    }

    .notice {
        color: #0d6efd;
    }

    .warning {
        color: #e60000;
    }

    .nousers {
        text-align: center;
        margin: 8px 0;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding: 8px;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
    }

    .icon {
        height: 18px;
        width: auto;
    }

    .compwarn {
        background-color: #ffffcc;
        margin-bottom: 15px;
        padding: 10px 20px;
        text-align: center;
    }

    .verifybtn {
        width: 75px;
        margin-left: 10px;
        margin-bottom: 8px;
    }

    .btn-yes {
        background-color: #33adff;
        color: #ffffff;
    }

    .btn-no {
        background-color: #850021;
        color: #ffffff;
    }

    .verifybox {
        border: 5px solid #000099;
        padding: 10px;
        background-color: #e6f7ff;
    }

    .modalbox {
        margin-bottom: 20px;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>